<template>
  <v-row
    class="fill-height"
  >
    <v-col
      cols="8"
      md="8"
      xl="9"
    >
      <baidu-map
        :scroll-wheel-zoom="true"
        :center="center"
        :zoom="zoom"
        class="fill-height"
        @ready="handler"
      >
        <bm-scale anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-scale>
        <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
      </baidu-map>
    </v-col>

    <v-col
      cols="4"
      md="4"
      xl="3"
    >
      <v-card
        class="mx-auto"
        flat
      >
        <v-alert
          border="left"
          color="error"
          dark
        >
          {{ $vuetify.lang.t('$vuetify.sosAlarm.list') }}
        </v-alert>

        <v-card-text
          class="pa-1 full-height"
        >
          <v-card
            v-for="(sa, index) in sosAlarmList"
            :key="`sos-alarm-${ index }`"
            class="mb-1"
            @click="handleCardClick(sa)"
          >
            <v-card-title
              class="text-body-1"
            >
              <span
              >
                {{ sa.patientName }}
              </span>

              <span
                class="ml-2"
              >
                {{ $vuetify.lang.t(`$vuetify.gender.${ sa.gender.toLowerCase() }`) }}
              </span>

              <span
                class="ml-2"
              >
                {{ sa.age }} {{ $vuetify.lang.t('$vuetify.sosAlarm.age') }}
              </span>
              <v-spacer />
              {{ sa.alarmTime | moment('YYYY-MM-DD HH:mm:ss') }}
            </v-card-title>

            <v-list-item
            >
              <v-list-item-content>
                <v-list-item-title
                  class="text-body-2 grey--text"
                >
                  {{ sa.patientPhone }}
                </v-list-item-title>

              </v-list-item-content>
              <v-list-item-action
                class="text-body-2 grey--text"
              >
                {{ sa.patientPhone }}
              </v-list-item-action>
            </v-list-item>

            <v-card-actions>
              <span
                class="text-body-2 grey--text ml-2"
              >
                {{ sa.address }}
              </span>
              <v-spacer />
              <v-btn
                color="info"
                small
                @click.native="handleProgressClick(sa)"
              >
                {{ $vuetify.lang.t('$vuetify.sosAlarm.progress') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog
      v-model="progressDialog"
      persistent
      max-width="600"
    >
      <do-form
        v-if="progressDialog"
        @submit="handleProgressDialogSubmit"
        @cancel="progressDialog = false"
      />
    </v-dialog>
  </v-row>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      DoForm: () => import('./DoForm')
    },

    data () {
      return {
        center: {lng: 0, lat: 0},
        zoom: 3,
        BMap: null,
        map: null,
        mapMarkers: new Map(),
        sosAlarmList: [],
        markerSelected: null,
        timer: null,
        progressDialog: false,
        editItem: null
      }
    },

    computed: {

      ...mapGetters({
        sosAlarms: 'sosAlarm/sosAlarms',
        authUser: 'auth/user'
      })

    },

    watch: {
      BMap: {
         handler: function(newValue) {
            if (newValue) {
              this.initialize()
            }
         }
      }
    },

    beforeDestroy () {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },

    methods: {
      ...mapActions({
        queryAllReadySosAlarm: 'sosAlarm/queryAllReadySosAlarm',
        sosAlarmProgress: 'sosAlarm/progress'
      }),

      initialize () {
        this.timeTask()
        this.timer = setInterval(() => {
          this.timeTask()
        }, 5000)
      },

      handler ({BMap, map}) {
        this.center.lng = 116.404
        this.center.lat = 39.915
        this.zoom = 11
        this.BMap = BMap
        this.map = map
      },

      timeTask () {
        this.queryAllReadySosAlarm().then( () => {
          this.sosAlarmList = this.sosAlarms
          this.sosAlarmList.forEach( (element) => this.generateMapPoint(element) )
        })
      },

      generateMapPoint (item) {
        let marker = this.mapMarkers.get(item.id)
        if ( !marker) {
          let point = new this.BMap.Point(item.longitude, item.latitude)
          let marker = new this.BMap.Marker(point)
          this.map.addOverlay(marker)
          this.mapMarkers.set(item.id, marker)
        }
      },

      handleCardClick (item) {
        if (item.id) {
          if (this.markerSelected) {
            this.markerSelected.setAnimation(0)
            this.markerSelected.setTop(false)
          }
          let marker = this.mapMarkers.get(item.id)
          this.markerSelected = marker
          this.map.centerAndZoom(marker.getPosition(), 18)
          marker.setAnimation(2)
          marker.setTop(true)

        }
      },

      handleProgressClick(item) {
        this.editItem = item
        this.progressDialog = true
      },

      handleProgressDialogSubmit (data) {
        this.sosAlarmProgress({id: this.editItem.id, data: data}).then( () => {
          let index = this.sosAlarmList.findIndex( (element) => element.id === this.editItem.id)
          if (index !== -1) {
            this.sosAlarmList.splice(index, 1)
          }

          let marker = this.mapMarkers.get(this.editItem.id)
          if (marker) {
            this.map.removeOverlay(marker)
            this.mapMarkers.delete(this.editItem.id)
          }
          this.progressDialog = false
        })
      }
    }
  }
</script>

<style type="scss" scoped>
.map {
  width: 100%;
  height: 800px;
}
.full-height {
  height: calc(100vh - 112px - 88px - 56px - 16px);
  overflow: scroll;
}
</style>
